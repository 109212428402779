import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// reactstrap components
import {
  Container,
  Row,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Nav,
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import { GApageView } from "../../shared/gaUtils";
import "../observe/Observe.css";
import RoomScan from "./room_scan/RoomScan";
import ReflectiveQuestion from "./reflective_question/ReflectiveQuestion";
import Actions from "./Actions";
import constant from "../../shared/constant";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import {
  Backdrop,
  CircularProgress
} from '@material-ui/core';

class Observe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      obData: {},
      loading: false
    };
  }

  componentDidMount() {
    document.body.classList.add("observe-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Observe");
    const observationId = localStorage.getItem(constant["OBSERVATION_ID"]);
    if (observationId !== null) {
      this.loadCurrentObserveData(observationId);
    } else {
      toast.info("Please select a classroom to observe or start new");
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("observe-page");
    document.body.classList.remove("sidebar-collapse");
  }

  loadCurrentObserveData = (observationId) => {
    axios
      .get(`/api/observations/${observationId}`)
      .then((res) => {
        console.log("res.......", res.data)
        if (res.data.indicators && res.data.questions) {
          this.setState({ obData: res.data }, () => {
            if (this.props.auth.user.role === constant["ROLE"]["SCHOOL_ADMIN"]) {
              if (
                this.state.obData &&
                this.state.obData.observer &&
                this.props.auth.user.id !== this.state.obData.observer._id
              ) {
                this.props.history.push("/debrief");
              }
            }
          });
        } 
        if(!res.data.indicators || res.data.indicators.length === 0) {
          this.getIndicators(res.data);
        }
        if(!res.data.questions || res.data.questions.length === 0) {
          this.getQuestions(res.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error("Please check your netwrok connection.");
        } else {
          toast.error(
            err.message || "Error occured while getting observation data."
          );
        }
        // this.props.history.goBack();
      });
  };

  getIndicators = (obData) => {
    axios
      .get("/api/indicators")
      .then((res) => {
        if (res.data) {
          const indicators = res.data.map((indicator) => {
            const components = indicator.components.map((component) => {
              return component._id;
            });
            return {
              ...indicator,
              notes: [],
              checked: false,
              components: components,
            };
          });
          let obDataObj = obData;
          obDataObj.indicators = indicators;
          this.setState({ obData: obDataObj });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error("Please check your netwrok connection.");
        } else {
          toast.error(
            err.message || "Error occured while retrieving indicators."
          );
        }
        // this.props.history.goBack();
      });
  };

  getQuestions = (obData) => {
    axios
      .get("/api/questions")
      .then((res) => {
        if (res.data) {
          const questions = res.data.map((question) => {
            const components = question.components.map((component) => {
              return component._id;
            });
            return {
              ...question,
              notes: "",
              answer: "",
              components: components,
            };
          });
          let obDataObj = obData;
          obDataObj.questions = questions;
          this.setState({ obData: obDataObj });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error("Please check your netwrok connection.");
        } else {
          toast.error(
            err.message || "Error occured while retrieving indicators."
          );
        }
        // this.props.history.goBack();
      });
  };

  removeNote = (indicators, indIndex, noteIndex) => {
    let obData = this.state.obData;
    obData.indicators = indicators;
    this.setState({ obData: obData }, () => {
      axios
        .post(`/api/observations/deleteNoteImage`, {
          observeId: obData._id,
          indIndex: indIndex,
          noteIndex: noteIndex,
        })
        .then((res) => {
          axios
            .put(`/api/observations/${obData._id}`, { obData: obData })
            .then((res) => {
              this.setState({ obData: res.data });
            })
            .catch((err) => {
              console.log(err);
              if (err.response) {
                toast.error(err.response.data.message);
              } else if (err.request) {
                toast.error("Please check your network connection.");
              } else {
                toast.error(
                  err.message ||
                    "Error occured while updating observation data."
                );
              }
            });
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error("Please check your netwrok connection.");
          } else {
            toast.error(
              err.message || "Error occured while updating observation data."
            );
          }
        });
    });
  };

  saveObservationData = (type, data) => {
    const obData = this.state.obData;
    if (type === constant["DATA_SAVE_TYPE"]["INDICATORS"]) {
      obData.indicators.map((ind) => ind._id === data._id ? data : ind)

    } else if (type === constant["DATA_SAVE_TYPE"]["QUESTIONS"]) {
      obData.questions.map((ind) => ind._id === data._id ? data : ind)
    } else {
      obData.actions.map((ind) => ind._id === data._id ? data : ind)
    }
    obData.observer = this.props.auth.user.id;
    this.setState({ obData: obData });
    this.saveDataIntoDB(obData);
  };

  saveDataIntoDB = async (obData) => {
    if (obData._id) {
      const noteImageRequests = [];
      obData.indicators.forEach((indicator, i) => {
        if(indicator.notes) {
          indicator.notes.forEach((note, j) => {
            if (note.image && typeof note.image !== "string") {
              const formPayload = new FormData();
              formPayload.append("file", note.image);
              formPayload.append("noteIndex", j);
              formPayload.append("indIndex", i);
              console.log(i);
              formPayload.append("observationId", obData._id);
              noteImageRequests.push(
                axios.post("/api/observations/uploadNoteImage", formPayload)
              );
            }
          });
        }
      });
      axios.all(noteImageRequests).then(
        axios.spread((...responses) => {
          let updatedObData = obData;
          responses.forEach((response) => {
            updatedObData.indicators[response.data.indIndex].notes[
              response.data.noteIndex
            ].image = response.data.path;
          });
          axios
            .put(`/api/observations/${obData._id}`, { obData: updatedObData })
            .then((res) => {
              this.setState({ obData: res.data });
            })
            .catch((err) => {
              console.log(err);
              if (err.response) {
                toast.error(err.response.data.message);
              } else if (err.request) {
                toast.error("Please check your netwrok connection.");
              } else {
                toast.error(
                  err.message ||
                    "Error occured while updating observation data."
                );
              }
            });
        })
      );
    }
  };

  render() {
    return (
      <>
        <Backdrop className="backdrop" open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DropdownScrollNavbar
          location={this.props.location}
          observer={
            this.state.obData &&
            this.state.obData.observer &&
            this.state.obData.observer._id
          }
        />
        <ToastContainer
          position="bottom-center"
          autoClose={100000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ color: "white" }}
        />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container className="session-bar">
                <Row className="padding-lg default-back-color text-white title-container">
                  <span style={{ marginRight: "60px" }}>
                    Teacher: {this.state.obData.teacher}
                  </span>
                  <span style={{ marginRight: "60px" }}>
                    Grade: {this.state.obData.grade}
                  </span>
                  <span style={{ marginRight: "60px" }}>
                    Lesson: {this.state.obData.topic}
                  </span>
                  <span style={{ marginRight: "60px" }}>
                    Created:{" "}
                    {moment(this.state.obData.date).format("MM/DD/YYYY h:mm a")}
                  </span>
                  <span style={{ marginRight: "60px" }}>
                    Last Modified:{" "}
                    {moment(this.state.obData.dateModified).format(
                      "MM/DD/YYYY h:mm a"
                    )}
                  </span>
                </Row>
              </Container>
              <Container className="board">
                <Row className="mt-5">
                  <div className="mr-auto ml-auto col-md-12">
                    <Nav
                      className="nav-pills-info nav-pills-icons justify-content-center"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={
                            this.state.tabs === 1
                              ? "active font-crm"
                              : "font-crm"
                          }
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ tabs: 1 });
                          }}
                        >
                          {this.state.tabs === 1 && (
                            <img
                              src={require("assets/img/Icon-RoomScan.png")}
                              alt=""
                            />
                          )}
                          {this.state.tabs === 2 && (
                            <img
                              src={require("assets/img/Icon-RoomScan-blue.png")}
                              alt=""
                            />
                          )}
                          {this.state.tabs === 3 && (
                            <img
                              src={require("assets/img/Icon-RoomScan-blue.png")}
                              alt=""
                            />
                          )}
                          <span className="mt-3">Room Scan</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            this.state.tabs === 2
                              ? "active font-crm"
                              : "font-crm"
                          }
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ tabs: 2 });
                          }}
                        >
                          {this.state.tabs === 1 && (
                            <img
                              src={require("assets/img/Icon02-Observe.png")}
                              alt=""
                            />
                          )}
                          {this.state.tabs === 2 && (
                            <img
                              src={require("assets/img/Icon02-Observe-white.png")}
                              alt=""
                            />
                          )}
                          {this.state.tabs === 3 && (
                            <img
                              src={require("assets/img/Icon02-Observe.png")}
                              alt=""
                            />
                          )}
                          <span className="mt-3">Observe</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            this.state.tabs === 3
                              ? "active font-crm"
                              : "font-crm"
                          }
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ tabs: 3 });
                          }}
                        >
                          {this.state.tabs === 1 && (
                            <img
                              src={require("assets/img/question-mark.png")}
                              alt=""
                            />
                          )}
                          {this.state.tabs === 2 && (
                            <img
                              src={require("assets/img/question-mark.png")}
                              alt=""
                            />
                          )}
                          {this.state.tabs === 3 && (
                            <img
                              src={require("assets/img/question-mark-white.png")}
                              alt=""
                            />
                          )}
                          <span className="mt-3">Reflective Questions</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="text-center"
                      activeTab={"tabs" + this.state.tabs}
                    >
                      <TabPane tabId="tabs1">
                        <RoomScan
                          obData={this.state.obData}
                          indicators={this.state.obData.indicators}
                          saveObservationData={this.saveObservationData}
                          removeNote={this.removeNote}
                        />
                      </TabPane>
                      <TabPane tabId="tabs2">
                        <Actions
                          obData={this.state.obData}
                          indicators={this.state.obData.indicators}
                          saveObservationData={this.saveObservationData}
                          removeNote={this.removeNote}
                        />
                      </TabPane>
                      <TabPane tabId="tabs3">
                        <ReflectiveQuestion
                          obData={this.state.obData}
                          saveObservationData={this.saveObservationData}
                          removeNote={this.removeNote}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

Observe.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Observe);
