import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import constant from "../../shared/constant";
import axios from "axios";
import { useHistory, Link } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

function DropdownScrollNavbar(props) {
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(" navbar-transparent");
  const [loginButtonColor, setLoginButtonColor] = React.useState("default-back-color");
  const [obData, setObData] = React.useState();


  React.useEffect(() => {
    localStorage.getItem(constant["OBSERVATION_ID"]) && axios
      .get(`/api/observations/${localStorage.getItem(constant["OBSERVATION_ID"])}`)
      .then((res) => {
        setObData(res.data);
      }).catch((err) => {
        console.log(err);
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log('Please check your netwrok connection.');
        } else {
          console.log(err.message || 'Error occured while saving observation data.');
        }
      });

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");

      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor(" navbar-transparent");
      }
    };

    if (props.auth.isAuthenticated) {
      setNavbarColor("");
      setLoginButtonColor('default-back-color');
    } else {
      window.addEventListener("scroll", updateNavbarColor);
      setLoginButtonColor('color-domain-1');
    }

    return function cleanup() {
      if (!props.auth.isAuthenticated) {
        window.removeEventListener("scroll", updateNavbarColor);
      }
    };
  }, [props]);

  const onLogoutClick = (e) => {
    e.preventDefault();
    localStorage.removeItem(constant['OBSERVATION_ID']);
    props.logoutUser();
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <div>
              <NavbarBrand to="/" tag={Link} id="navbar-brand" className="navbar-brand">
                <img src={require("assets/img/logo-rubric.png")} alt="" />
              </NavbarBrand>
            </div>
            <UncontrolledTooltip target="navbar-brand">
              Designed by BCI
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              {localStorage.getItem('isSchoolAdmin') === 'true' ?
                <>
                  <NavItem className="dropdown">
                    <NavLink className={`dropdown-toggle ${props.location && props.location.pathname.includes('/schooladmin') ? 'selected-background-color' : ''}`} href="/schooladmin">
                      <i
                        aria-hidden={true}
                        className="now-ui-icons business_chart-pie-36"
                      ></i>
                      <p>Admin</p>
                    </NavLink>
                  </NavItem>
                  <NavItem className="dropdown">
                    <NavLink className={`dropdown-toggle ${props.location && props.location.pathname.includes('/adminPortfolio') ? 'selected-background-color' : ''}`} href="/adminPortfolio">
                      <img src={require("assets/img/Icon05-Portfolio.png")} alt="portfolio" />
                      <p>Portfolio</p>
                    </NavLink>
                  </NavItem>
                </>
                :
                <>
                  {props.auth.isAuthenticated && props.auth.user.role === 'SuperAdmin' &&
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        caret
                        color="default"
                        data-toggle="dropdown"
                        href="#pablo"
                        id="navbarDropdownMenuLink"
                        className={`${props.location && props.location.pathname.includes('admin') ? 'selected-background-color' : ''}`}
                        nav
                        onClick={(e) => e.preventDefault()}
                      >
                        <i
                          aria-hidden={true}
                          className="now-ui-icons business_chart-pie-36"
                        ></i>
                        <p>Admin</p>
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                        <DropdownItem tag={Link} to="/indicators">
                          <i className="now-ui-icons education_paper" />Indicators
                        </DropdownItem>
                      </DropdownMenu>
                      <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                        <DropdownItem tag={Link} to="/districts">
                          <i className="now-ui-icons education_paper" />Website User Control
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/admin/users">
                          <i className="now-ui-icons business_badge" />Users
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/admin-reset-password">
                          <i className="now-ui-icons gestures_tap-01" />Reset password
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                  {(props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] || props.auth.user.role === constant['ROLE']['OBSERVER']) ?
                    <>
                    </>
                    :
                    props.auth.user.role !== constant['ROLE']['DISTRICT_ADMIN'] &&
                    <NavItem className="dropdown">
                      <NavLink className={` ${(props.location && props.location.pathname === '/') ? 'selected-background-color' : ''}`} href="/">
                        <i className="now-ui-icons design_bullet-list-67"></i>
                        <p>Dashboard</p>
                      </NavLink>
                    </NavItem>
                  }
                  {props.auth.isAuthenticated && props.auth.user.role !== constant['ROLE']['DISTRICT_ADMIN'] &&
                    <>
                      {(props.auth.user.role === constant['ROLE']['OBSERVER'] || props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] || (props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN'] && props.auth.user.id === props.observer)) &&
                        <>
                          <UncontrolledDropdown nav>
                            <DropdownToggle
                              caret
                              color="default"
                              data-toggle="dropdown"
                              href="#pablo"
                              id="navbarDropdownMenuLink"
                              className={`dropdown-toggle ${(props.location && props.location.pathname.includes('/observe')) ? 'selected-background-color' : ''}`}
                              nav
                              onClick={(e) => e.preventDefault()}
                            >
                              {(props.location && props.location.pathname.includes('/observe')) ?
                                <img src={require("assets/img/Icon02-Observe-gray.png")} alt="Observe" />
                                :
                                <img src={require("assets/img/Icon02-Observe.png")} alt="Observe" />
                              }
                              <p>Observe</p>
                            </DropdownToggle>
                            <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                              <DropdownItem tag={Link} to={{ pathname: "/current", state: { obData: obData } }}>
                                <i className="now-ui-icons gestures_tap-01" />Current Observation
                              </DropdownItem>
                              <DropdownItem tag={Link} to={{ pathname: "/classroom", state: { new: true } }}>
                                <i className="now-ui-icons education_hat" />Start New
                              </DropdownItem>
                              <DropdownItem tag={Link} to="/">
                                <i className="now-ui-icons files_paper" />List Observations
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          
                          <NavItem className="dropdown">
                            <NavLink className={`dropdown-toggle ${props.location && props.location.pathname.includes('/consolidate') ? 'selected-background-color' : ''}`} href="/consolidate">
                              {props.location && props.location.pathname.includes('/consolidate') ?
                                <img src={require("assets/img/Icon03-Consolidate-gray.png")} alt="Consolidate" />
                                :
                                <img src={require("assets/img/Icon03-Consolidate.png")} alt="Consolidate" />
                              }
                              <p>Consolidate</p>
                            </NavLink>
                          </NavItem>
                        </>
                      }
                      {
                        props.auth.user.role !== constant['ROLE']['MANAGER'] &&
                        <NavItem className="dropdown">
                          <NavLink className={`dropdown-toggle ${props.location && props.location.pathname.includes('/debrief') ? 'selected-background-color' : ''}`} href="/debrief">
                            {props.location && props.location.pathname.includes('/debrief') ?
                              <img src={require("assets/img/Icon04-Debrief.png")} alt="Debrief" />
                              :
                              <img src={require("assets/img/Icon04-Debrief.png")} alt="Debrief" />
                            }
                            <p>Debrief</p>
                          </NavLink>
                        </NavItem>
                      }
                    </>
                  }
                  {
                    ((props.auth.user.role === constant['ROLE']['MANAGER'] && props.location.pathname.includes('/schooladmin')) || props.auth.user.role === constant['ROLE']['OBSERVER'] || props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) &&
                    // <Link className="navItem" style={{display: "flex", alignItems: 'center', gap: '5px', marginRight: '5px', padding: '0.5rem 0.7rem', margin: '0 1rem'}} to={(props.auth.user.role === constant['ROLE']['MANAGER']) ? '/adminportfolio' : '/portfolio'} >
                    //   <img src={require("assets/img/Icon05-Portfolio.png")} alt="portfolio" style={{width: '25px'}} />
                    //   Portfolio
                    // </Link>
                    <NavItem className="dropdown">
                      <NavLink className={`dropdown-toggle ${props.location && props.location.pathname.includes('/portfolio') ? 'selected-background-color' : ''}`} href={`${props.auth.user.role === constant['ROLE']['MANAGER'] ? "/adminportfolio" : "portfolio"}`}>
                        <img src={require("assets/img/Icon05-Portfolio.png")} alt="portfolio"/>
                        <p>Portfolio</p>
                      </NavLink>
                    </NavItem>
                  }
                </>
              }
              {props.auth.isAuthenticated ?
                <NavItem>
                  <Button
                    onClick={onLogoutClick}
                    className={`nav-link nav-btn ${loginButtonColor}`}
                  >
                    <p>LOGOUT</p>
                  </Button>
                </NavItem>
                :
                <>
                  <NavItem>
                    <Button
                      className={`nav-link nav-btn ${loginButtonColor}`}
                      href="/login"
                      target="_self"
                    >
                      <p>LOGIN</p>
                    </Button>
                  </NavItem>
                  <NavItem>
                    <Button
                      className={`nav-link nav-btn ${loginButtonColor}`}
                      href="/register"
                      target="_self"
                    >
                      <p>SIGNUP</p>
                    </Button>
                  </NavItem>
                </>
              }
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

DropdownScrollNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(DropdownScrollNavbar);