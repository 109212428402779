import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import moment from "moment";
import _, { first } from 'lodash';
import {
  Container,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import './AdminPortfolio.css'
import constant from "../../shared/constant";
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chart from 'react-apexcharts';
import StarRatings from 'react-star-ratings';
import { BiCalendar } from "react-icons/bi";

export class AdminPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSchool: {},
      modalSchool: {},
      currentDistrict: null,
      observations: [],
      originObservatons: [],
      ratings: [],
      components: [],
      maxDate: null,
      minDate: null,
      totalRated: 0,
      starCount: [0, 0, 0, 0, 0, 0, 0],
      starVal: [10, 10, 10, 10, 10, 10, 10],
      starColor: ['#493CC2', '#6D63CE', '#928ADA', '#A49DE0', '#B6B1E7', '#C8C4ED', '#DBD8F3'],
      firstNum: [ 0, 0, 0, 0, 0 ],
      secondNum: [ 4, 7, 5, 3, 8],
      options: {
        plotOptions: {
          radialBar: {
            track: {
              background: '#FFFFFF20',
            },
            hollow: {
              margin: 5,
              size: "40%"
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: false,
                color: "#888",
                fontSize: "8px"
              },
              value: {
                color: "#FFF",
                fontSize: "15px",
                offsetY: 6,
                show: true
              }
            }
          }
        },
        colors: [
          '#FFF'
        ],
        stroke: {
          lineCap: "round",
        },
        labels: ["Percentage"]
      },
      completed: true,
      notCompleted: true,
      schools: []
    }
    this.onChangeMinDate = this.onChangeMinDate.bind(this);
    this.onChangeMaxDate = this.onChangeMaxDate.bind(this);
    this.CustomInput = this.CustomInput.bind(this);
  }

  componentDidMount () {
    document.body.classList.add("admin-portfolio-page");
    axios.get('/api/components')
    .then((res) => {
      this.setState({components: res.data})
    })
    this.props.auth.user.role === constant['ROLE']['MANAGER'] && axios.post('/api/districts/get-school', {schoolId: localStorage.getItem("schoolId")})
    .then((res) => {
      this.setState({schools: res.data})
    })
    
    axios
    .get('/api/districts')
    .then((res) => {
      if (res.data && res.data.length > 0) {
        let districts = [ ...res.data ];
        districts.forEach(district => {
          if (district.schools && district.schools.length > 0) {
            district.schools.forEach( async (school) => {
              if (school.admins && school.admins.length > 0) {
                let tempAdminEmail = this.props.auth.user.role === constant['ROLE']['MANAGER'] ? this.state.schools.admins.length > 0 ? this.state.schools.admins[0].email : this.props.auth.user.email : this.props.auth.user.email
                if (school.admins.filter(admin => admin.email.toLowerCase() === tempAdminEmail.toLowerCase()).length > 0) {
                  let observations = [];
                  await Promise.all(district.schools.map( async (school) => {
                    try {
                      const res = await axios
                      .post('/api/observations',
                        {
                          schoolId: school._id,
                          districtId: district._id,
                          role: "SchoolAdmin",
                          dashboard: true
                        })
                      if (res.data && res.data.length > 0) {
                        res.data.forEach( (observation) => {
                          observations.push(observation);
                        })
                      }
                    } catch {
                      console.log('Please check your netwrok connection.')
                    }
                  }))
                  this.setState({
                    currentSchool: {...school, admins: [...school.admins], users: [...school.users]},
                    modalSchool: {...school},
                    currentDistrict: district,
                  })
                  let tempDate = []
                  let tempAAA = [];
                  for (let tempUser of school.users) {
                    for (let tempObservation of observations) {
                      if (tempUser.userId === tempObservation.observer._id) {
                        tempAAA.push(tempObservation)
                      }
                    }
                  }
                  if (tempAAA.length > 0) {
                    for (let observation of tempAAA) {
                      tempDate.push(new Date(observation.dateModified))
                    }
                    let maxDate = new Date(Math.max(...tempDate));
                    let minDate = new Date(Math.min(...tempDate));
                    this.setState({
                      maxDate: moment(maxDate).format('MM/DD/YYYY'),
                      minDate: moment(minDate).format('MM/DD/YYYY'),
                    })
                  }
                  let temp = this.state.currentSchool.users.filter(user => user.count > 0);
                  const tempObservers = []
                  for (let observation of observations) {
                    for (let item of temp) {
                      if (observation.observer._id === item.userId) {
                        tempObservers.push(observation)
                      }
                    }
                  }
                  if (tempObservers.length === 0 ) toast.warning("There are no observations.")
                  for (let observation of tempObservers) {
                    for (let rating of observation.ratings) {
                      rating.rating = this.getRatingFromIndicators(rating)
                    }
                  }
                  this.setState({
                    originObservatons: tempObservers,
                  })
                  this.setState({firstNum: [0, 0, 0, 0, 0]})
                  this.calculateAverage(tempObservers)
                }
              }
            })
          }
        })
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  componentWillUnmount () {
    document.body.classList.remove("user-control-page");
  }

  calculateAverage = (observations) => {
    this.setState({
      observations: observations
    })
    let tempRatings = []
    if (observations.length > 0) {
      for (let rating of observations[0].ratings) {
        tempRatings.push({component: rating.component})
      }
    }
    let ratings = tempRatings.map(tempRating => {
      let filteredObservations = observations.filter(observation => {
        return observation.ratings.some(rating => rating.component === tempRating.component)
      })

      let filteredRatings = filteredObservations.map(observation => {
        return observation.ratings.find(rating => rating.component === tempRating.component);
      });

      let filterZeroRatings = filteredRatings.filter(item => item.rating > 0)

      let totalRating = filterZeroRatings.reduce((acc, rating) => {
        return acc + rating.rating;
      }, 0);

      let averageRating = totalRating / (filterZeroRatings.length === 0 ? 1 : filterZeroRatings.length);
      let maxRating = filterZeroRatings.length > 0 ? Math.max(...filterZeroRatings.map(rating => rating.rating)) : 0
      let minRating = filterZeroRatings.length > 0 ? Math.min(...filterZeroRatings.map(rating => rating.rating)) : 0
      return {
        component: tempRating.component,
        average: averageRating.toFixed(2),
        high: maxRating,
        low: minRating,
        componentAmount: filterZeroRatings.length,
        observationAmount: observations.length
      };
    })

    this.setState({ ratings: ratings })
    let tempFirstNum = [0, 0, 0, 0, 0]
    for (let observation of observations) {
      observation.ratings.map((rating) => {
        const component = _.find(this.state.components, {_id: rating.component});
        if (rating.rating !== -1) {
          if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === component.domain.index) {
            tempFirstNum[0]++;
          } else if (constant['OBSERVATION_TYPE']['REGULATION'] === component.domain.index) {
            tempFirstNum[1]++;
          } else if (constant['OBSERVATION_TYPE']['SAFETY'] === component.domain.index) {
            tempFirstNum[3]++;
          } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === component.domain.index) {
            tempFirstNum[4]++;
          } else {
            tempFirstNum[2]++;
          }
          const tempTotal = this.state.totalRated + 1
          this.setState({totalRated : tempTotal})
          const tempCount = this.state.starCount.slice()
          tempCount[6-rating.rating * 2] = this.state.starCount[6-rating.rating * 2] + 1
          this.setState({starCount: tempCount})
        }
      })
      this.setState({firstNum: [...tempFirstNum]})
    }
    const tempRated = this.state.starVal.slice();
    tempRated.map((temp, index) => {
      tempRated[index] = this.state.totalRated === 0 ? 0 : Math.round(this.state.starCount[index] / this.state.totalRated * 100);
    });
    this.setState({starVal : tempRated});

    return;
  }

  setRatings = (value) => {
    switch (value) {
      case 6:
        return -1;
      case 5:
        return 0;
      case 4:
        return 1;
      case 3:
        return 1.5;
      case 2:
        return 2;
      case 1:
        return 2.5;
      case 0:
        return 3;
      case -1:
        return -1;
      default:
        return '';
    }
  }

  getRatingFromIndicators(rating) {
    if (rating)
      return this.setRatings(rating.rating);
    return this.setRatings(6);
  }

  onChangeMinDate = (date) => {
    let maxDate = moment(this.state.maxDate).startOf('day');
    
    let selectedDate = moment(date).startOf('day');
    console.log("Selected----", selectedDate)
    console.log("minD---", maxDate)
    if (selectedDate > maxDate) {
      toast.warning("Please select the Date less than Maximum Date!")
    } else {
      this.setState({minDate: moment(date).format('MM/DD/YYYY')})
      let temp = [...this.state.originObservatons]
      temp = temp.filter(item => {
        const itemDate = new Date(moment(item.dateModified).startOf('day'))
        const filterDate = new Date(moment(date).startOf('day'));
        return itemDate >= filterDate
      })
      if (temp.length > 0) {
        this.setState({observations: temp})
        this.calculateAverage(temp)
      } else {
        this.setState({ observations: [] })
        toast.warning("There are no observations.")
      }
    }
  }

  onChangeMaxDate = (date) => {
    console.log("Date::::", date)
    let minDate = moment(this.state.minDate).startOf('day');

    let selectedDate = moment(date).startOf('day');
    console.log("Selected::::", selectedDate)
    console.log("minD::::", minDate)
    if (selectedDate >= minDate) {
      this.setState({maxDate: moment(date).format('MM/DD/YYYY')})
      let temp = [...this.state.originObservatons]
      temp = temp.filter(item => {
        const itemDate = new Date(moment(item.dateModified).startOf('day'))
        const filterDate = new Date(moment(date).startOf('day'));
        return itemDate <= filterDate
      })
      if (temp.length > 0) {
        this.setState({observations: temp})
        this.calculateAverage(temp) 
      } else {
        this.setState({ observations: [] })
        toast.warning("There are no observations.")
      }
      
    } else {
      toast.warning("Please select the Date more than Minimum Date!")
    }
  }

  renderStarRadial = () => {
    var options = {
      responsive: [{
        breakpoint: 360,
        options: {
          chart: {
            width: 100
          },
          pie: {
            expandOnClick: false,
            innerSize: '50%',
            allowPointSelect: true,
            cursor: 'pointer',
            size: 70,
            showInLegend: true,
            dataLabels: {
                enabled: false
            }
          }
        }
      }],
      legend:{
        show: false,
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          if( (3 - seriesIndex / 2) === 1 || (3 - seriesIndex / 2) === 0)
          {
            return (
              '<div style="padding: 8px;">' +
              "<span>" + (3 - seriesIndex / 2) +
              " Star" +
              "</span>" +
              "</div>"
            );
          }
          else{
          return (
            '<div style="padding: 8px;">' +
            "<span>" + (3 - seriesIndex / 2) +
            " Stars" +
            "</span>" +
            "</div>"
          );}
        }
      },
      fill:{
        colors: this.state.starColor
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return (Math.round(val) + "%");
        }
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: this.state.starColor,
        width: 8,
        dashArray: 0,   
      }
      };
      return(
        <div id="chart">
          <Chart options={options} series={this.state.starVal} type="donut" width="200px" height="200px"/>
        </div>
      );
  }

  renderStarList = () => {
    var arr = Array.apply(null, Array(7))
    return arr.map((val, index) =>{
      return(
        <Row className="mx-2 my-3">
          <Col xs={8} >
            <StarRatings
              rating={3-index*0.5}
              // rating={this.state.starVal[index] * 3/100}
              starDimension="20px"
              starSpacing="5px"
              starRatedColor="#493cc2"
              numberOfStars={3}
            />
          </Col>
          <Col xs={4} className={`color-white ${this.getStarBackColor(index)}`} style={{borderRadius: "10px",  display: 'flex', alignItems: 'center', justifyContent: 'center', transform: `translateY(6%)`}}>
            {
              this.state.starVal[index] + "%"
            }
          </Col>
        </Row>
      );
    })
  }

  getStarBackColor = (index) => {
    return 'star-color-' + (index + 1);
  }

  renderTables = () => {
    var arr = Array.apply(null, Array(5))
    return arr.map((val, index) =>{
      return(
        <Col className={`m-1 color-white ${this.getBackColor(index)}`} style={{borderRadius: "13px"}}>
          <Row className="m-1 mt-3 text-left" style={{fontSize: 12, height: "45px"}}>
            {
              this.getComponentText(index)
            }
          </Row>
          <Row>
            <Col md={7} className="mb-3 text-right font-weight-bold" style={{fontSize: 25}}>
            {
              this.getResult(index)
            }
            </Col>
            <Col md={5} style={{marginLeft: -45, marginTop:-13}}>
            {
              this.getPercent(index)
            }
            </Col>
          </Row>
          <Row className="m-1 text-left" style={{fontSize: 13}}>
          {
            this.getDomainText(index)
          }
          </Row>
        </Col>
      );
    })
  }

  getBackColor = (index) => {
    return 'color-domain-' + (index + 1);
  }

  getComponentText = (index) => {
    if(index === 0)
      return "Relationships and Family Culture";
    if(index === 1)
      return "Regulation";
    if(index === 2)
      return "Language of Trauma";
    if(index === 3)
      return "Safety";
    if(index === 4)
      return "Discipline and Empowerment";   
  }

  getResult = (index) => {
    if (this.state.observations.length === 0) {
      return 0
    } else {
      return (this.state.firstNum[index] / this.state.observations.length).toFixed(1);
    }
  }

  getPercent = (index) => {
    const value = this.state.observations.length === 0 ? 0 : Math.round(this.state.firstNum[index] / this.state.observations.length / this.state.secondNum[index] * 100);
    const arr = [value];
    return(
        <Chart
          options={this.state.options}
          series={arr}
          type="radialBar"
          width="120"
        />
    );
  }

  getDomainText = (index) => {
    if (index === 0) {
      return "out of 4 components"
    } else if (index === 1) {
      return "out of 7 components"
    } else if (index === 2) {
      return "out of 5 components"
    } else if (index === 3) {
      return "out of 3 components"
    } else if (index === 4) {
      return "out of 8 components"
    }
  }

  getBackColorByRating = (rating) => {
    if (rating >= 2.5) {
      return 'color-rating-1';
    } else if (rating === 2) {
      return 'color-rating-2';
    } else if (rating === 1.5) {
      return 'color-rating-3';
    } else if (rating === 1) {
      return 'color-rating-4';
    } else if (rating === 0) {
      return 'color-rating-5';
    }
    return 'color-back-white';
  }

  getComponentColor = (component) => {
    if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === component.domain.index) {
      return 'color-domain-1';
    } else if (constant['OBSERVATION_TYPE']['REGULATION'] === component.domain.index) {
      return 'color-domain-2';
    } else if (constant['OBSERVATION_TYPE']['SAFETY'] === component.domain.index) {
      return 'color-domain-4';
    } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === component.domain.index) {
      return 'color-domain-5';
    } else {
      return 'color-domain-3';
    }
  }

  renderComponents = () => {
    let rankedRatings = this.state.ratings.sort((a, b) => {return Number(b.average) - Number(a.average)})
    let tempIndex = rankedRatings.findIndex(item => item.average === "0.00")
    return rankedRatings.map((rating, cIndex) => {
      const component = _.find(this.state.components, { _id: rating.component });
      const { _id, name, index } = component;
      return (
        <>
          <tr key={_id} className={`${this.getBackColorByRating(rating.rating)} identity`} style={{borderBottom: cIndex === tempIndex-1 && "10px solid rgb(224 234 241)"}}>
            <td style={{padding: "0px 7px"}}>
              <div className={`ml-2 circle-badge ${this.getComponentColor(component)}`}>{index}</div>
            </td>
            <td style={{textAlign: "left", padding: "0px 7px"}}>{name}</td>
            <td style={{padding: "7px 7px"}}>{rating.average === "0.00" ? "N/A" : rating.average}</td>
            <td style={{padding: "7px 7px"}}>{rating.high === 0 ? "N/A" : rating.high}</td>
            <td style={{padding: "7px 7px"}}>{rating.low === 0 ? "N/A" : rating.low}</td>
            <td style={{padding: "7px 7px"}}>{rating.componentAmount === 0 ? "N/A" : rating.componentAmount + " / " + rating.observationAmount}</td>
            <td style={{padding: "7px 7px"}}>{rating.componentAmount === 0 ? "N/A" : ((rating.componentAmount/rating.observationAmount) * 100).toFixed(2)}</td>
          </tr>
        </>
      )
    })
  }

  renderPortfolio = () => {
    return (
      <div className='home' >
        <div className='panel'>
          <Container>
            {
              this.state.observations.length > 0 ? <Row style={{marginTop: "45px"}}>
              <Col xs={5} md={3}>
                <Row className="mt-3">
                  <div className='mr-auto ml-auto col-md-12'>
                    <Card>
                      <CardBody>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className='text-center'><span>Rating Percentage</span></th>
                            </tr>
                            <tr>{this.renderStarRadial()}</tr>
                          </thead>
                        </Table>
                        <div className="mb-5">
                          {
                            this.renderStarList()
                          }
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              </Col>
              <Col xs={13} md={9}>
                <Row className="mt-3">
                  <div className='col-md-12'>
                    <Card style={{marginBottom: "0px"}}>
                      <CardBody>
                        <Table responsive style={{marginBottom: "0px"}}>
                          <thead>
                            <tr>
                              <th className='text-center'></th>
                              <th className='text-left' style={{display: "flex", justifyContent: "center", fontSize: "18px"}}>
                                <span>Average Number of Components Rated in Each Observation By Domain</span>
                              </th>
                            </tr>
                          </thead>
                        </Table>
                        <Row>{this.renderTables()}</Row>
                      </CardBody>
                    </Card>
                    <Card className='mt-3'>
                      <CardBody>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="text-center card-text"></th>
                              <th className="text-center card-text">Component</th>
                              <th className="text-center card-text">Average</th>
                              <th className="text-center card-text">High</th>
                              <th className="text-center card-text">Low</th>
                              <th className="text-center card-text">Components / All Observations</th>
                              <th className="text-center card-text">Percentage(%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.renderComponents() }
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              </Col>
            </Row> : <Row style={{marginTop: '100px', justifyContent: 'center', fontSize: '20px'}}>The portfolio requires observations. Please select a different date range.</Row>
            }
            
          </Container>
        </div>
      </div>
      
    )
  }

  handleCompleted = (e) => {
    console.log(e.target.name, e.target.checked)
    let tempObservations = []
    if (this.state.notCompleted) {
      if (e.target.checked) {
        tempObservations = this.state.originObservatons.filter((item) => item.status === 2 || item.status === 1)
      } else {
        tempObservations = this.state.originObservatons.filter((item) => item.status === 1)
      }
      this.setState({completed: !this.state.completed})
    } else {
      this.setState({completed: true})
      tempObservations = this.state.originObservatons.filter((item) => item.status === 2)
      toast.warning("You should select 'Completed' or 'Not Completed'")
    }
    this.setState({
      observations: tempObservations
    })
    this.calculateAverage(tempObservations)
  }

  handleNotCompleted = (e) => {
    console.log(e.target.name, e.target.checked)
    let tempObservations = []
    if (this.state.completed) {
      if (e.target.checked) {
        tempObservations = this.state.originObservatons.filter((item) => item.status === 2 || item.status === 1)
      } else {
        tempObservations = this.state.originObservatons.filter((item) => item.status === 2)
      }
      this.setState({notCompleted: !this.state.notCompleted})
    } else {
      this.setState({notCompleted: true})
      tempObservations = this.state.originObservatons.filter((item) => item.status === 1)
      toast.warning("You should select 'Completed' or 'Not Completed'")
    }
    this.setState({
      observations: tempObservations
    })
    this.calculateAverage(tempObservations)
  }
  
  CustomInput(props) {
    return (
      <button className="date-picker-input" onClick={props.onClick}>
        {props.value}
        <BiCalendar className="date-picker-icon" />
      </button>
    );
  }

  render() {
    return (
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DropdownScrollNavbar  location={this.props.location}  />
        <div id="adminPortfolio" className='wrapper' ref={el => (this.componentRef = el)} >
          <div style={{display: "flex"}}>
            <Container className="session-bar">
              <Row className="padding-lg default-back-color text-white title-container">
                <span style={{ marginRight: '30px' }}>{this.state.modalSchool.name}</span>
                <span className='date-picker'>
                  <span style={{width: "100%"}}>Date Range:</span>
                  <div className='date-picker-container'>
                    <input 
                      type='date' 
                      className='date-picker-go'
                      onChange={(e) => this.onChangeMinDate(e.target.value)}
                      value={moment(this.state.minDate || new Date()).format('YYYY-MM-DD')}
                    />
                  </div>
                  <span>to</span>
                  <div className='date-picker-container' >
                    <input 
                      type='date' 
                      className='date-picker-go'
                      onChange={(e) => this.onChangeMaxDate(e.target.value)}
                      value={moment(this.state.maxDate || new Date()).format('YYYY-MM-DD')}
                    />
                  </div>
                </span>
                
                <span style={{ marginRight: '30px' }}>Created: {moment(new Date()).format('MM/DD/YYYY')}</span>
                <ReactToPrint
                  trigger={() => {
                    return <Button style={{ marginRight: '20px', width: "50px"}} className="default-back-color btn-print hide-printing" type="button">
                              <img src={require("assets/img/Icon-Print.png")} alt="print" />
                            </Button>;
                  }}
                  content={() => this.componentRef}
                />
                
                <span className='complete-status'>
                  <span>Include:</span>
                  <div>
                      <input type="checkbox" id="completed" name="completed" value="completed" checked={this.state.completed} onChange={(e) => this.handleCompleted(e)}></input>
                  <label for="vehicle1"> Completed </label>
                  </div>
                  <div>
                    <input type="checkbox" id="notCompleted" name="notCompleted" value="notCompleted" checked={this.state.notCompleted} onChange={(e) => this.handleNotCompleted(e)}></input>
                    <label for="vehicle1"> Not Completed </label>
                  </div>
                </span>
                
              </Row>
            </Container>
          </div>
          {
            this.renderPortfolio()
          }
        </div>
      </div>
    )
  }
}

AdminPortfolio.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired, 
  school: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors, 
  school: state.school
});

export default connect(
  mapStateToProps
)(AdminPortfolio);