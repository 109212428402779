import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
// reactstrap components
import {
  Table,
  FormGroup,
  Label,
  Input,
  Row,
  CardBody,
  Card,
  Button,
  Modal,
  ModalFooter
} from "reactstrap";
import constant from "../../../shared/constant";
import _, { isEmpty } from "lodash";
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class VisualIndicators extends Component {
  constructor(props) {
    super(props);

    this.onDrop = (files) => {
      if (files.length === 0) {
        return;
      }
      const file = Object.assign(files[0], { preview: URL.createObjectURL(files[0]) });
      this.setState(prevState => ({
        note: {
          ...prevState.note,
          image: file
        }
      }));
      this.setState({ files })
    };

    this.state = {
      errors: {},
      indicator: {},
      customizedIndicator: {},
      noteModal: false,
      note: {},
      nIndex: -1,
      indicators: [],
      file: null,
      inputState: true
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ indicators: props.indicators });
  }

  onChangeNote = (e) => {
    const text = e.target.value;
    this.setState(prevState => ({
      note: {
        ...prevState.note,
        text: text
      }
    }));
  }

  onChangeCustomizedIndicator = (e) => {
    let indicator = this.state.customizedIndicator;
    indicator.title = e.target.value;
    indicator.type = constant['OBSERVATION_TYPE']['CUSTOM'];
    indicator.checked = true;
    indicator.component = null;
    this.setState({ customizedIndicator: indicator });
  }

  onClickNoteIndicator(indicator, nIndex, note) {
    if (this.disableUserAccess()) return;
    this.setState({ indicator: indicator, note: note, nIndex: nIndex !== undefined ? nIndex : -1 }, () => {
      this.setState({ noteModal: true }, () => {
        setTimeout(() => {
          if(this.state.inputState === false) return;
          ReactDOM.findDOMNode(this.refs.noteInput).focus();
        }, 1)
      });
    });
  }

  onChangeCheckStatus = (e, _id) => {
    let indicators = this.state.indicators;
    let indicator = indicators.find((indicator) => { return indicator._id === _id });
    indicator.checked = e.target.checked;
    this.setState({ indicators: indicators }, () => {
      this.props.updateIndicators(indicator);
    });
  }

  addNote = () => {
    if (this.state.inputState === true && _.isEmpty(this.state.note)) {
      toast.error('Please add note.');
      return;
    };
    if (this.state.inputState === false && (_.isEmpty(this.state.note) || (!this.state.note || this.state.note === undefined))) {
      return;
    };

    let indicators = this.state.indicators;
    let indicator = indicators.find((indicator) => { return indicator._id === this.state.indicator._id });
    indicator.checked = true;
    if (this.state.nIndex === -1) {
      if(indicator.notes === null) indicator.notes = [];
      indicator.notes.push(this.state.note);
    } else {
      indicator.notes.splice(this.state.nIndex, 1, this.state.note);
    }
    this.setState({ indicator: indicator, nIndex: -1, note: {}, indicators: indicators }, () => {
      this.props.updateIndicators(indicators);
    });
  }

  onClickNoteRemove = (indicator, noteIndex) => {
    if (this.disableUserAccess()) return;
    let indicators = this.state.indicators;
    let ind = indicators.find((ind) => { return ind._id === indicator._id });
    const indIndex = indicators.findIndex((ind) => { return ind._id === indicator._id });
    ind.notes.splice(noteIndex, 1);
    this.props.removeNote(indicators, indIndex, noteIndex);
  }

  onClickNoteImageRemove = (indicator, noteIndex) => {
    if (this.disableUserAccess()) return;
    let indicators = this.state.indicators;
    let ind = indicators.find((ind) => { return ind._id === indicator._id });
    console.log("ind......", ind)
    const indIndex = indicators.findIndex((ind) => { return ind._id === indicator._id });
    console.log("indIndex.....", indIndex)
    console.log("noteIndex......", noteIndex)
    let note = ind.notes[noteIndex];
    note.image = null;
    ind.notes.splice(noteIndex, 1);
    console.log("indicators.....", indicators)
    this.props.removeNote(indicators, indIndex, noteIndex);
  }

  saveCustomizedIndicator = () => {
    if (this.disableUserAccess() || this.state.customizedIndicator.title === '' || _.isEmpty(this.state.customizedIndicator)) return;
    let indicators = this.state.indicators;
    if (this.state.customizedIndicator._id) { // Edit
      let indicator = indicators.find((indicator) => { return indicator._id === this.state.customizedIndicator._id });
      indicator.title = this.state.customizedIndicator.title;
    } else {
      indicators.push({ ...this.state.customizedIndicator, type: constant['OBSERVATION_TYPE']['CUSTOM'] });
    }
    this.setState({ customizedIndicator: { title: '', id: null } });
    this.props.updateIndicators(indicators);
  }

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      if (e.target.id === 'customIndicator') {
        this.saveCustomizedIndicator();
      } else if (e.target.id === 'note') {
        this.addNote();
      }
    }
  }

  deleteNote = (cIndicator) => {
    let tempIndicators = [...this.state.indicators]
    let index = tempIndicators.findIndex((item) => item._id === cIndicator._id)
    tempIndicators.splice(index, 1)
    this.setState({ indicators: tempIndicators }, () => {
      this.props.updateIndicators(tempIndicators);
    });
  }

  renderCustomizedIndicator = (cIndicator) => {
    this.setState({ customizedIndicator: {...cIndicator} })
  }

  renderCustomIndicators = () => {
    // if (this.state.indicators === undefined) return (<></>);
    let cIndicators = this.state.indicators.filter((indicator) => { return indicator.type === constant['OBSERVATION_TYPE']['CUSTOM'] });
    cIndicators.unshift({}); // Add empty indicator for new custom indicator
    const indicators = cIndicators.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return indicators.map((cIndicator, index) => {
      const { title, checked, _id, notes } = cIndicator;
      return (
        <>
          {index === 0 ?
            <tr>
              <td colSpan="2">
                <FormGroup>
                  <Input
                    disabled={this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false} 
                    onChange={this.onChangeCustomizedIndicator}
                    value={this.state.customizedIndicator.title}
                    placeholder=""
                    autoComplete="on"
                    type="textarea"
                    rows="4"
                    id="customIndicator"
                    onKeyPress={this.onKeyPress}
                  ></Input>
                </FormGroup>
                <Button
                  disabled={this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false} 
                  className="default-back-color font-crm mt-4"
                  onClick={this.saveCustomizedIndicator}
                  style={{cursor: this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] && "not-allowed"}}
                  size="md">
                  Save
                </Button>
              </td>
            </tr>
            :
            <tr key={_id}>
              <td className="text-left" style={{ verticalAlign: 'initial' }}>
                <FormGroup check style={{ marginLeft: '5px' }}>
                  <Label check>
                    <Input 
                      disabled={this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false} 
                      defaultChecked={checked} type="checkbox" onChange={(e) => this.onChangeCheckStatus(e, _id)} ></Input>
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
              </td>
              <td className="text-left" onClick={() => this.renderCustomizedIndicator(cIndicator)}>
                <h5 className="font-crm" style={{wordBreak: 'break-all'}}>
                  {title}
                </h5>
                {
                  this.renderNotes(cIndicator)
                }
              </td>
              <td className="text-right" style={{ verticalAlign: 'initial' }}>
                <Button
                  disabled={this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false} 
                  id="add-note"
                  style={{ backgroundColor: this.getBackgroundColor(), cursor: this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] && "not-allowed" }}
                  className="btn-icon btn-square btn"
                  onClick={()=> { this.onClickNoteIndicator(cIndicator); this.setState({inputState : false})}}
                  size="md">
                  <i className="now-ui-icons design_image"></i>
                </Button>
              </td>
              <td className="text-right" style={{ verticalAlign: 'top', paddingTop: '18px' }}>
                <div
                  onClick={() => this.deleteNote(cIndicator)}
                >
                  <i class="fa fa-trash" style={{color: "#648292", fontSize: '25px'}}></i>
                </div>
              </td>
            </tr>
          }
        </>
      );
    });
  }

  renderNotes = (indicator, modal = false) => {
    if (indicator.notes && indicator.notes.length > 0) {
      return indicator.notes.map((note, nIndex) => {
        return (
          <>
            <div className="note" style={{width: !modal ? 'inherit': '100%', overflow: modal ? 'hidden' : 'inherit'}}>
              <div className="d-flex align-items-center justify-content-between">
                <Label style={{ display: 'block', marginTop: '5px' }} id="edit-note" onClick={() => this.onClickNoteIndicator(indicator, nIndex, note)}>
                  {note.text &&
                    <>
                  -  {note.text}
                    </>
                  }
                </Label>
                {modal && note.text &&
                  <Button
                    onClick={()=> { this.onClickNoteRemove(indicator, nIndex); this.setState({ noteModal: false, note: {}, nIndex: -1 })}}
                    className="btn-icon btn-neutral btn note-image-remove">
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </Button>
                }
              </div>
              {note.image &&
                <div className="note-image d-flex align-items-center justify-content-between">
                  <img
                    src={typeof(note.image) === "object" ? note.image.preview : note.image}
                    alt="note_image"
                    width={50}
                    style={{cursor: 'pointer'}}
                    onClick={() => { this.onClickNoteIndicator(indicator, nIndex, note); }} />
                  {modal &&
                    <Button
                      onClick={()=> { this.onClickNoteImageRemove(indicator, nIndex); this.setState({ noteModal: false, note: {}, nIndex: -1 })}}
                      className="btn-icon btn-neutral btn note-image-remove">
                      <i className="now-ui-icons ui-1_simple-remove"></i>
                    </Button>
                  }
                </div>
              }
            </div>
          </>
        );
      });
    }
  }

  renderVisualIndicators = () => {
    if (this.state.indicators === undefined) return (<></>);
    let indicators = this.state.indicators.filter((indicator) => { return indicator.type === this.props.type });

    const comp = (a, b) => {
      return a.title > b.title ? 1 : (a.title < b.title ? -1 : 0);
    }

    indicators = indicators.sort(comp);

    return indicators.map((indicator) => {
      const { title, checked, _id } = indicator;
      return (
        <>
          <tr key={_id}>
            <td className="text-left" style={{ verticalAlign: 'initial' }}>
              <FormGroup check style={{ marginLeft: '5px' }}>
                <Label check>
                  <Input  disabled={this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false} 
                    checked={checked} type="checkbox" 
                    onChange={(e) => this.onChangeCheckStatus(e, _id)} /> 
                  <span className="form-check-sign"></span>
                </Label>
              </FormGroup>
            </td>
            <td className="text-left">
              <h5 className="font-crm">
                {title}
              </h5>
              {
                this.renderNotes(indicator)
              }
            </td>
            <td className="text-right" style={{ verticalAlign: 'initial' }}>
              <Button
                id="add-note"
                disabled={this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                style={{ backgroundColor: this.getBackgroundColor(), cursor: this.props.status === constant['OBSERVATION_STATUS']['COMPLETED'] && "not-allowed" }}
                className="btn-icon btn-round btn"
                onClick={() => this.onClickNoteIndicator(indicator)}
                size="sm">
                <i className="now-ui-icons ui-1_simple-add"></i>
              </Button>
            </td>
          </tr>
        </>
      );
    });
  }

  getBackgroundColor = () => {
    if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === this.props.type) {
      return '#5bada3';
    } else if (constant['OBSERVATION_TYPE']['REGULATION'] === this.props.type) {
      return '#158574';
    } else if (constant['OBSERVATION_TYPE']['SAFETY'] === this.props.type) {
      return '#cd1f3d';
    } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === this.props.type) {
      return '#136ba5';
    } else {
      return '#d6af20';
    }
  }

  getTableHeaderTitle = () => {
    if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === this.props.type) {
      return 'Relationships and Family Culture';
    } else if (constant['OBSERVATION_TYPE']['REGULATION'] === this.props.type) {
      return 'Regulation';
    } else if (constant['OBSERVATION_TYPE']['SAFETY'] === this.props.type) {
      return 'Safety';
    } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === this.props.type) {
      return 'Discipline and Empowerment';
    } else {
      return 'Visual Indicators';
    }
  }

  disableUserAccess = () => {
    if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ||
      this.props.auth.user.role === constant['ROLE']['TEACHER']) {
      return true;
    } else if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      if (this.state.obData && this.state.obData.observer && (this.props.auth.user.id !== this.state.obData.observer._id)) {
        return true;
      }
    }
    return false;
  }

  render() {

    return (
      <>
        <Row className="mr-auto ml-auto col-lg-12">
          <div className="col-lg-7">
            <Card className='mt-3'>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-left">
                        <div style={{ textAlign: "center", backgroundColor: this.getBackgroundColor(), width: '30px', height: '25px' }}>
                          <span style={{ color: 'white' }}>{this.props.type}</span>
                        </div>
                      </th>
                      <th className="text-left">{this.getTableHeaderTitle()}</th>
                      <th className="text-right">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.renderVisualIndicators()
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-5">
            <Card className="mt-3">
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-left">
                        <div style={{ textAlign: "center", backgroundColor: "#648292", width: '30px', height: '25px' }}>
                          <span style={{ color: 'white' }}></span>
                        </div>
                      </th>
                      <th className="text-left">Other Observations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.renderCustomIndicators()
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
        <Modal
          modalClassName="modal-action"
          isOpen={this.state.noteModal}
          toggle={() => this.setState({ noteModal: false, note: '', nIndex: -1 , inputState : true})}
        >
          <div className="modal-title default-back-color">
            <h5>Enter Visual Indicator</h5>
          </div>
          <div className="modal-body just">
            <div className="notes">
              {
                this.renderNotes(this.state.indicator, true)
              }
            </div>
            {
              this.state.inputState &&
              <FormGroup>
                <Input
                  id="note"
                  placeholder="You can write your note here..."
                  onChange={this.onChangeNote}
                  type="textarea"
                  value={this.state.note && this.state.note.text}
                  rows="1"
                  ref="noteInput"
                  onKeyPress={this.onKeyPress}
                ></Input>
              </FormGroup>
            }
            <Dropzone
              onDrop={this.onDrop}
              accept=".jpeg,.jpg,.ai,.png,.webp"
              minSize={0}
              maxSize={constant['MAX_UPLOAD_IMAGE_SIZE']}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="container">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {this.state.note && this.state.note.image ?
                      <img src={(this.state.note && this.state.note.image && typeof this.state.note.image === 'string' ? this.state.note.image : this.state.note.image.preview)} alt="note_image" />
                      :
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', padding: '16px 16px 16px 32px', color: 'black' }}>
                        <img src={require("assets/img/pic.png")} style={{ width: 40 }} alt="note_image" />
                        <div style={{ padding: '0 16px 0 32px' }}>
                          <p className={'thumbnail-intro'}>Drag or Upload Graphic Here</p>
                          <span>.jpeg,.jpg,.ai,.png,.webp</span>
                        </div>
                      </div>
                    }
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <ModalFooter className="justify-content-center">
            <Button
                className="default-back-color font-crm action-save mr-3"
                onClick={() => { this.setState({ noteModal: false, note: {}, nIndex: -1, inputState: true }) }}
              >
                Cancel
            </Button>
            <Button
              className="default-back-color font-crm action-save ml-3"
              onClick={() => { this.addNote(); this.setState({ noteModal: false, note: {}, nIndex: -1, inputState: true }) }}
            >
              Save {'&'} Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

VisualIndicators.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(VisualIndicators)
